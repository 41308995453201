import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import theme from "../style/theme";
import { breakpoints } from "../style/theme";

const Wrapper = styled.div`
  width: 100%;
  margin: 1rem 0 0;
`

const BreadcrumbsWrapper = styled.ol`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 0;
`

const Breadcrumb = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  margin: 0.25rem 0 0.25rem;
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: .3s;
    border-top: 1px solid transparent;
    //border-bottom: 1px solid transparent;
    &:hover {
      //border-bottom: 1px solid ${theme.palette.text.secondary};
      //color: ${theme.palette.text.secondary};
      ${theme.palette.rainbow}
    }
  }
  span {
    display: block;
    color: ${theme.palette.text.secondary};
    font-family: "Montserrat", "游ゴシック",YuGothic,"ヒラギノ角ゴ ProN W3","Hiragino Kaku Gothic ProN","メイリオ",Meiryo,sans-serif;
    font-style: normal;
    font-weight: 300;
    letter-spacing: .13rem;
  }
  &:not(:first-child) {
    &:before {
      content: url('data:image/svg+xml;utf-8,<svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M6.5 10.5l3-3-3-3" stroke="%23ccc" stroke-linecap="square"></path></svg>');
      margin: auto 0.5rem;
      height: 14px;
      @media screen and (max-width: ${breakpoints.sm}px) {
        margin: auto 0.5rem auto 0;
      }
    }
  }
  &:last-child {
    span {
      color: ${theme.palette.text.primary};
      &:hover {
        text-decoration: none;
      }
    }
  }
`

export const Breadcrumbs = ({siteUrl, links}) => {
  return(
    <Wrapper>
      <BreadcrumbsWrapper itemScope itemType={"https://schema.org/BreadcrumbList"}>
        <Breadcrumb itemProp={"itemListElement"} itemScope itemType={"https://schema.org/ListItem"}>
          <Link href={'/'} itemProp="item">
            <span itemProp="name" content="TOP">
              <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="15" height="15"><path d="M7.825.12a.5.5 0 00-.65 0L0 6.27v7.23A1.5 1.5 0 001.5 15h4a.5.5 0 00.5-.5v-3a1.5 1.5 0 013 0v3a.5.5 0 00.5.5h4a1.5 1.5 0 001.5-1.5V6.27L7.825.12z" fill="currentColor"></path></svg>
            </span>
          </Link>
          <meta itemProp="position" content="1" />
        </Breadcrumb>
        {
          links.map((link, i) => {
            return (
              <Breadcrumb key={i} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                <Link to={link.url} itemProp="item">
                  <span itemProp="name">{link.title}</span>
                </Link>
                <meta itemProp="position" content={i+2} />
              </Breadcrumb>
            )
          })
        }
      </BreadcrumbsWrapper>
    </Wrapper>
  )
}