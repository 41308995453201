import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { A8Links } from '../utils/A8Links';
import { shuffle } from "../utils/Shuffle";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  &:hover {
    a > img {
      transform: scale(1.2);
    }
  }
`

const LinkWrapper = styled.div`
  display: flex;
  width: 100%;
  background: transparent;
  background-color: transparent;
  border: none;
  text-decoration: none!important;
  transition: .5s;
  &:hover {
    border: none;
    box-shadow: none;
    background-color: #eee;
  }
  @media screen and (max-width: ${props => props.breakpoint}px) {
    margin: 0.25rem 0;
  }
`

const Article = styled.article`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  transition: .3s;
  box-sizing: border-box;
`

const ImgWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  height: auto;
  aspect-ratio: 200/112;
  overflow:hidden;
  border-radius: 0.15rem;
  a > img {
    width: 100%;
    max-width: 200px;
    height: auto;
    aspect-ratio: 200/112;
    transition: 1s!important;
    border-radius: 0.15rem;
  }
  @media screen and (max-width: ${props => props.breakpoint - 1}px) {
    max-width: 100px;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
`

const PR = styled.span`
  margin: 0 0.25rem 0 0;
  font-size: 0.8rem;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  letter-spacing: .13em;
  color: ${props => props.fontColor};
  transition: .5s;
  @media screen and (max-width: ${props => props.breakpoint - 1}px) {
    font-size: 0.6rem;
  }
`

const Title = styled.div`
  height: 100%;
  margin: 0.75rem 0 0;
  a {
    display: block;
    height: 100%;
    text-decoration: none;
    font-size: 1.0rem;
    letter-spacing: .13em;
    font-weight: normal;
    color: ${props => props.fontColor};
    transition: .5s;
    @media screen and (max-width: ${props => props.breakpoint - 1}px) {
      font-size: 0.8rem;
    }
  }
  img {
    display: none;
  }
`

export const ArticlePhotoRowAffiliateLink = ({fontColor, backgroundColor, breakpoint, category=null}) => {

  const [ link, setLink] = useState(null);

  useEffect(() => {

    let l =
      category
      ?
      shuffle(A8Links.filter(link => link.categories.includes(category) && link.text))[0]
      :
      shuffle(A8Links.filter(link => link.sUrl && link.text))[0];

  if (!l) {
    l = shuffle(A8Links.filter(link => link.sUrl && link.text))[0];
  }

  if (l) {
    //setLink(l);
  }

  }, [])

  if (!link) {
    return <></>
  }

  return (
    <Wrapper breakpoint={breakpoint} borderColor={backgroundColor}>
      <LinkWrapper key={link.text} breakpoint={breakpoint}>
        <Article>
          <ImgWrapper breakpoint={breakpoint} description={link.text} fontColor={fontColor} dangerouslySetInnerHTML={{ __html: link.sUrl }}>
          </ImgWrapper>
          <TitleWrapper backgroundColor={backgroundColor} breakpoint={breakpoint}>
            <PR breakpoint={breakpoint} fontColor={backgroundColor}>PR</PR>
            <Title breakpoint={breakpoint} fontColor={backgroundColor} dangerouslySetInnerHTML={{ __html: link.text }}>{}</Title>
          </TitleWrapper>
        </Article>
      </LinkWrapper>
    </Wrapper>
  )
}
