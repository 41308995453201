import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import theme from "../style/theme";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 2rem 0;
`

const PageLink = styled(Link)`
  display: block;
  padding: 0.25rem 0.5rem;
  margin: 0 0.25rem;
  color: ${theme.palette.primary.main};
  border: 1px solid ${theme.palette.primary.main};
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  transition: .5s;
  &:hover {
    color: ${theme.palette.white};
    background-color: ${theme.palette.primary.main};
    text-decoration: none;
  }
  &[active='true'] {
    color: ${theme.palette.white};
    background-color: ${theme.palette.primary.main};
    text-decoration: none;
  }
`

const createPages = (totalPages) => {
  const pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }
  return pages;
}

export const Pagination = ({url, currentPage, totalPages}) => {
  return (
    <Wrapper>
      <PageLink to={`${url}/${1 === currentPage ? 1 : currentPage - 1}`}>Prev</PageLink>
      <PageLink to={`${url}/${1}`} active={1 === currentPage ? 'true' : 'false'}>{1}</PageLink>
      {
        currentPage > 2 &&
        <PageLink to={`${url}/${currentPage - 1}`} active={1 === currentPage ? 'true' : 'false'}>{currentPage - 1}</PageLink>
      }
      {
        currentPage != 1 && currentPage != totalPages &&
        <PageLink to={`${url}/${currentPage}`} active={'true'}>{currentPage}</PageLink>
      }
      {
        currentPage < totalPages - 1 &&
        <PageLink to={`${url}/${currentPage + 1}`} active={totalPages - 1 === currentPage ? 'true' : 'false'}>{currentPage + 1}</PageLink>
      }
      {
        //createPages(totalPages).map(page => {
        //  return(<PageLink to={`${url}/${page}`} active={page === currentPage ? 'true' : 'false'}>{page}</PageLink>)
        //})
      }
      {
        totalPages > 1 &&
        <PageLink to={`${url}/${totalPages}`} active={totalPages === currentPage ? 'true' : 'false'}>{totalPages}</PageLink>
      }
      <PageLink to={`${url}/${currentPage === totalPages ? totalPages : currentPage + 1}`}>Next</PageLink>
    </Wrapper>
  )
}
