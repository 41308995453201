import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "gatsby";
import Img from "gatsby-image";

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
`

const LinkWrapper = styled(Link)`
  display: flex;
  width: 100%;
  background: transparent;
  background-color: transparent;
  border: none;
  text-decoration: none!important;
  transition: .5s;
  &:hover {
    border: none;
    box-shadow: none;
    //background-color: rgba(27, 31, 34, .85);
    background-color: #eee;
    //time, h1 {
    //  color: #fff;
    //}
  }
  @media screen and (max-width: ${props => props.breakpoint}px) {
    margin: 0.25rem 0;
  }
`

const Article = styled.article`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  transition: .3s;
  box-sizing: border-box;
  .gatsby-image-wrapper {
    border-radius: 0.15rem;
  }
  picture > img {
    transition: 1s!important;
    border-radius: 0.15rem;
  }
  &:hover {
    picture > img {
      transform: scale(1.2);
    }
  }
`

const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 200px;
  background-image: ${props => props.img};
  @media screen and (max-width: ${props => props.breakpoint - 1}px) {
    max-width: 100px;
  }
  &::after {
    position: absolute;
    content: '';
    bottom: 0;
    padding: 1rem;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    opacity: 0;
    color: ${props => props.fontColor};
    font-size: 1.2rem;
    letter-spacing: .13em;
    transition: .3s;
    @media screen and (max-width: ${props => props.breakpoint - 1}px) {
      font-size: 0.9rem;
    }
  }
  &:hover {
    //&::after {
    //  content: "${props => props.description}";
    //  background: rgba(27, 31, 34, .85);
    //  opacity: 1;
    //}
  }
`

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 0.5rem;
  box-sizing: border-box;
`

const Date = styled.time`
  margin: 0 0.25rem 0 0;
  font-size: 0.8rem;
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  letter-spacing: .13em;
  color: ${props => props.fontColor};
  transition: .5s;
  @media screen and (max-width: ${props => props.breakpoint - 1}px) {
    font-size: 0.6rem;
  }
`

const Title = styled.h1`
  width: 100%;
  font-size: 1.0rem;
  letter-spacing: .13em;
  font-weight: normal;
  color: ${props => props.fontColor};
  transition: .5s;
  word-break: break-word;
  @media screen and (max-width: ${props => props.breakpoint - 1}px) {
    font-size: 0.8rem;
  }
`

export const ArticlePhotoRowLink = ({frontmatter, fontColor, backgroundColor, breakpoint}) => {
  return (
    <Wrapper breakpoint={breakpoint} borderColor={backgroundColor}>
      <LinkWrapper to={`/posts/${frontmatter.id}`} key={frontmatter.id} breakpoint={breakpoint}>
        <Article>
          <ImgWrapper breakpoint={breakpoint} img={frontmatter.featuredImage.childImageSharp.fluid} description={frontmatter.description} fontColor={fontColor}>
            <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} />
          </ImgWrapper>
          <TitleWrapper backgroundColor={backgroundColor} breakpoint={breakpoint}>
            <Date breakpoint={breakpoint} fontColor={backgroundColor}>{moment(frontmatter.createdDate).format("YYYY.MM.DD")}</Date>
            <Title breakpoint={breakpoint} fontColor={backgroundColor}>{frontmatter.title}</Title>
          </TitleWrapper>
        </Article>
      </LinkWrapper>
    </Wrapper>
  )
}