import React from "react";
import styled from "styled-components";
import { ArticlePhotoRowLink } from "./ArticlePhotoRowLink";
import { ArticlePhotoRowAffiliateLink } from "./ArticlePhotoRowAffiliateLink";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem 10%;
  width: 100%;
  margin: 2rem 0;
  @media screen and (max-width: ${props => props.breakpoint}px) {
    gap: 1rem 10%;
    margin: 2rem 0;
  }
`

export const ArticlePhotoRowLinks = ({edges, fontColor, backgroundColor, breakpoint, isAdSense=true, category=null}) => {
  return (
    <Wrapper breakpoint={breakpoint}>
      {
        edges && edges.map((page) => (
          (
            <ArticlePhotoRowLink
              frontmatter={page.node.frontmatter}
              fontColor={fontColor}
              backgroundColor={backgroundColor}
              breakpoint={breakpoint}
            />
          )
        ))
      }
      {
        isAdSense &&
        <ArticlePhotoRowAffiliateLink
          category={category}
          fontColor={fontColor}
          backgroundColor={backgroundColor}
          breakpoint={breakpoint}
        />
      }
    </Wrapper>
  )
}